import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import { getDaysSinceEpoch, getNextMonday } from '../utils/time'

const areas = ['Denk dome', 'Kleine soos', 'Heerensociëteit', 'Heerensociëteit']

const getVariation = date =>
  ((getDaysSinceEpoch(date) + 2) / 7) % areas.length | 0

const rotateArray = (arr, amount) =>
  arr.slice(amount).concat(arr.slice(0, amount))

const getSchedule = (bewoners, date) =>
  rotateArray(areas, getVariation(date)).reduce((schedule, area, index) => {
    schedule[bewoners[index].id] = area
    return schedule
  }, {})

export default ({ data }) => {
  const bewoners = data.allBewonersYaml.edges
    .map(edge => edge.node)
    .sort((a, b) => {
      if (a.van > b.van) return 1
      if (a.van < b.van) return -1
      return 0
    })

  const schedule = getSchedule(bewoners)

  const dateOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }
  const deadline = getNextMonday().toLocaleDateString('nl', dateOptions)

  const rows = bewoners.map(bewoner => (
    <tr key={bewoner.id}>
      <td>
        <img
          src={bewoner.image}
          css={{
            width: 32,
            display: 'inline-block',
            verticalAlign: 'middle',
            margin: '0 8px 0 0',
            borderRadius: 16,
          }}
          alt=""
        />
        {bewoner.name}
      </td>
      <td>{schedule[bewoner.id]}</td>
    </tr>
  ))

  return (
    <Layout>
      <h2
        css={{
          lineHeight: '1.5',
          span: {
            color: '#777',
            display: 'block',
            fontSize: '.8em',
          },
        }}
      >
        Schoonmaakrooster
        <span>Deadline: {deadline}</span>
      </h2>
      <table>
        <thead>
          <tr>
            <th>Persoon</th>
            <th>Taak</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </Layout>
  )
}

export const pageQuery = graphql`
  query SchoonmaakPage {
    allBewonersYaml(filter: { tot: { eq: null } }) {
      edges {
        node {
          id
          name: title
          image
          van
        }
      }
    }
  }
`
